import React, { Component } from "react";


import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import ImgMap from "../components/others/img_map"
import BodyList from "../components/others/body_list"

import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`
{
 
 strapiCliente(locale: {eq: "en"})  {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    Lista {
      descripcion
      id
      titulo
      lista {
        id
        titulo
      }
    }
    mapa {
        url
      }
  }
}
`


class Cliente extends Component {

    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        <Layout props={this.props.props}>
                            <Banner banner={data.strapiCliente.Banner[0]} />
                            <section className="padding-section-int">
                                <div className="container">
                                <ImgMap url={data.strapiCliente.mapa.url} />
                                <BodyList list={data.strapiCliente.Lista} />
                                </div>
                            </section>
                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default Cliente
